import { logout, signIn } from 'api/auth'

class Auth {
  constructor() {
    this.getProfile = this.getProfile.bind(this)
    this.handleAuthentication = this.handleAuthentication.bind(this)
    this.isAuthenticated = this.isAuthenticated.bind(this)
    this.signOut = this.signOut.bind(this)
  }

  getProfile() {
    return this.profile
  }

  getIdToken() {
    return this.idToken
  }

  isAuthenticated() {
    if (localStorage.getItem('expiryTime') > new Date().getTime()) return true

    if (window.location.pathname != '/login') {
      localStorage.clear()
      localStorage.setItem('redirectionUrl', window.location.pathname)
    }

    return false
  }

  handleAuthentication(username, password) {
    return new Promise(resolve => {
      signIn(username, password)
        .then(({ token_info, user }) => {
          this.idToken = token_info.access_token
          this.expiresAt = new Date().getTime() + token_info.expires_in

          localStorage.setItem('accessToken', this.idToken)
          localStorage.setItem('expiryTime', this.expiresAt)
          localStorage.setItem('user', JSON.stringify(user))

          resolve({ token_info })
        })
        .catch(() => {
          resolve({ error: 'Wrong Email or Password!' })
        })
    })
  }

  signOut() {
    return new Promise(resolve => {
      logout()
        .then(response => {
          if (response == true) {
            this.idToken = null
            this.expiresAt = null

            localStorage.clear()

            resolve(response)
          }
        })
        .catch(() => {
          resolve(false)
        })
    })
  }
}

const auth0Client = new Auth()

export default auth0Client

import { atom } from 'recoil'

export const loadingState = atom({
  key: 'loadingState',
  default: false
})

export const activeSidebarLink = atom({
  key: 'activeSidebarLink',
  default: 'My Companies'
})

export const isLoggedInState = atom({
  key: 'userLoggedIn',
  default: undefined
})

export const setFormActionDisableState = atom({
  key: 'formActionState',
  default: false
})

import { atom, selectorFamily } from 'recoil'
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

export const companyModalStepState = atom({
  key: 'createCompanyModalStepState',
  default: 1
})

export const companyModalState = atom({
  key: 'createCompanyModalState',
  default: false
})

export const companiesState = atom({
  key: 'allCompaniesListState',
  default: []
})

export const setPerPageState = atom({
  key: 'perPageState',
  default: 25
})

export const setPageState = atom({
  key: 'pageState',
  default: 1
})

export const setTotalState = atom({
  key: 'totalState',
  default: 0
})

export const currentUserNameState = selectorFamily({
  key: 'CurrentUserName',
  get:
    params =>
    ({ get }) =>
      get(companiesState).find(company => company.id == params.id)
})

export const actualIncomeStatementState = atom({
  key: 'SpecificCompanyActualIncomeStatementState',
  default: []
})

export const projectionIncomeStatementState = atom({
  key: 'SpecificCompanyProjectionIncomeStatementState',
  default: []
})

export const driverIncomeStatementState = atom({
  key: 'SpecificCompanyDriverIncomeStatementState',
  default: []
})

export const selectedCompanyState = atom({
  key: 'selectedCompanyState',
  default: {}
})

export const selectedCompanyIdState = atom({
  key: 'selectedCompanyIdState',
  default: {},
  effects_UNSTABLE: [persistAtom]
})

export const actualBalanceSheetState = atom({
  key: 'SpecificCompanyActualBalanceSheetState',
  default: []
})

export const projectionBalanceSheetState = atom({
  key: 'SpecificCompanyProjectionBalanceSheetState',
  default: []
})

export const actualCashFlowState = atom({
  key: 'SpecificCompanyActualCashFlowState',
  default: []
})

export const projectionCashFlowState = atom({
  key: 'SpecificCompanyProjectionCashFlowState',
  default: []
})

export const incomeStatementGraphDriverState = atom({
  key: 'SpecificIncomeStatementGraphDriverState',
  default: {}
})

export const incomeStatementGraphProjectedState = atom({
  key: 'SpecificIncomeStatementGraphProjectedState',
  default: {}
})

export const incomeStatementFinancialsState = atom({
  key: 'SpecificIncomeStatementFinancialsState',
  default: null
})

export const balanceSheetGraphDriverState = atom({
  key: 'SpecificBalanceSheetGraphDriverState',
  default: {}
})

export const balanceSheetGraphProjectedState = atom({
  key: 'SpecificBalanceSheetGraphProjectedState',
  default: {}
})

export const balanceSheetFinancialsState = atom({
  key: 'SpecificBalanceSheetFinancialsState',
  default: null
})

export const cashFlowsGraphDriverState = atom({
  key: 'SpecificCashFlowsGraphDriverState',
  default: {}
})

export const cashFlowsGraphProjectedState = atom({
  key: 'SpecificCashFlowsGraphProjectedState',
  default: {}
})

export const cashFlowsFinancialsState = atom({
  key: 'SpecificCashFinancialsState',
  default: null
})

export const updateGraphResponse = atom({
  key: 'SpecifiCompanyUpdateGraph',
  default: {}
})

export const incomeStatementDriversList = [
  {
    type: 'areaspline',
    title: 'Revenue Growth',
    key: 'revenue_percent',
    axisTitle: 'In Percentage %',
    tooltip: '%',
    modalTitle: 'Revenue Growth (%)'
  },
  {
    type: 'areaspline',
    title: 'COGS ( % Revenue )',
    key: 'cost_of_goods_sold_percent',
    axisTitle: 'As % of Revenue',
    tooltip: '%',
    modalTitle: 'COGS ( % Revenue )'
  },
  {
    type: 'areaspline',
    title: 'SG&A ( % Revenue )',
    key: 'sga_percent',
    axisTitle: 'As % of Revenue',
    tooltip: '%',
    modalTitle: 'SG&A ( % Revenue)'
  },
  {
    type: 'areaspline',
    title: 'D&A ( % Revenue )',
    key: 'duration_of_asset_percent',
    axisTitle: 'As % of Revenue',
    tooltip: '%',
    modalTitle: 'D&A ( % Revenue )'
  },
  {
    type: 'areaspline',
    title: 'Other Income/ Expense ( % Revenue )',
    key: 'other_income_percent',
    axisTitle: 'As % of Revenue',
    tooltip: '%',
    modalTitle: 'Other Income/ Expense ( % Revenue )'
  },
  {
    type: 'areaspline',
    title: 'Net Interest Expense',
    key: 'net_interest',
    axisTitle: 'USD (in billions)',
    tooltip: 'B',
    modalTitle: 'Net Interest Expense ($ in millions)'
  }
]

export const incomeStatementProjectionsList = [
  {
    type: 'column',
    title: 'Total Revenue',
    key: 'total_revenue',
    axisTitle: 'USD ( in billions )',
    tooltip: 'B'
  },
  {
    type: 'column',
    title: 'Gross Profit',
    key: 'gross_profit',
    axisTitle: 'USD ( in billions )',
    tooltip: 'B'
  },
  {
    type: 'column',
    title: 'EBIT',
    key: 'ebit',
    axisTitle: 'USD ( in billions )',
    tooltip: 'B'
  },
  {
    type: 'column',
    title: 'EBITDA',
    key: 'ebitda',
    axisTitle: 'USD ( in billions )',
    tooltip: 'B'
  },
  {
    type: 'column',
    title: 'EBT',
    key: 'ebt',
    axisTitle: 'USD ( in billions )',
    tooltip: 'B'
  },
  {
    type: 'column',
    title: 'Net Income',
    key: 'net_income',
    axisTitle: 'USD ( in billions )',
    tooltip: 'B'
  }
]

export const incomeStatementEntries = {
  total_revenue: 'Total Revenue',
  revenue_percent: 'Revenue Y-O-Y Growth rate',
  cost_of_goods_sold: '(-) Cost of Goods Sold (COGS)',
  gross_profit: 'Gross Profit',
  gross_profit_margin: 'Gross Margin %',
  sga_expenses: '(-) Sales, General & Administrative Expense (SG&A)',
  ebit: 'Earnings Before Interest & Taxes ("EBIT")',
  ebit_margin: 'EBIT Margin %',
  duration_of_asset: '(+) Depreciation & Amortization ("D&A")',
  ebitda: 'Earnings Before Interest, Taxes, Depreciation, & Amortization ("EBITDA")',
  ebitda_margin: 'EBITDA Margin %',
  ebitt: 'EBIT',
  net_interest: '(+/-) Net Interest Income / (Expense)',
  other_income: '(+/-) Other Income / (Expense)',
  ebt: 'EBT',
  ebt_margin: 'EBT Margin %',
  taxes: '(-) Taxes',
  net_income: 'Net Income',
  net_income_margin: 'Net Income Margin %'
}

export const highlights = [
  'Total Revenue',
  'Gross Profit',
  'Earnings Before Interest & Taxes ("EBIT")',
  'Earnings Before Interest, Taxes, Depreciation, & Amortization ("EBITDA")',
  'Net Income'
]

export const dollarSign = [
  'Total Revenue',
  'Gross Profit',
  'Earnings Before Interest & Taxes ("EBIT")',
  'Earnings Before Interest, Taxes, Depreciation, & Amortization ("EBITDA")',
  'EBIT',
  'EBT',
  'Net Income'
]

export const kpisTabList = ['INCOME STATEMENT', 'BALANCE SHEET', 'CASH FLOW STATEMENT']

export const kpisNestedTabList = ['METRICS', 'RATIOS']

export const kpiIncomeStatementActualList = [
  { id: '1', title: 'Revenue CAGR', key: 'revenue_cagr' },
  { id: '2', title: 'COGS CAGR', key: 'cogs_cagr' },
  { id: '3', title: 'Gross Profit CAGR', key: 'gross_profit_cagr' },
  { id: '4', title: 'EBITDA CAGR', key: 'ebitda_cagr' },
  { id: '5', title: 'Avg Gross Margin', key: 'avg_gross_margin' },
  { id: '6', title: 'Avg SG&A % of Revenue', key: 'avg_sga_as_revenue' },
  { id: '7', title: 'Avg Bit Margin', key: 'avg_ebit_margin' },
  { id: '8', title: 'Avg D&A as % of Revenue', key: 'avg_dnaas_revenue' },
  { id: '9', title: 'Avg EBITDA Margin', key: 'avg_ebitda_margin' },
  { id: '10', title: 'Avg EBT Margin', key: 'avg_ebt_margin' },
  { id: '11', title: 'Avg Net Income Margin', key: 'avg_net_income_margin' }
]

export const ratioProfitabilityEnteries = {
  gross_margin: 'Gross Margin: Gross Profit / Revenue',
  ebitda_margin: 'EBITDA Margin: EBITDA / Revenue',
  operating_profit_margin: 'Operating Profit Margin: EBIT / Revenue',
  pre_tax_margin: 'Pre-Tax Margin: EBT / Revenue',
  net_income_margin: 'Net Income Margin: Net Income / Revenue'
}

export const ratioReturnEnteries = {
  operating_return_assets: 'Operating Return on Assets (ROA): EBIT / Average Total Assets',
  cash_return_assets: 'Cash Return on Assets (ROA): CFO / Average Total Assets',
  return_assets: 'Return on Assets (ROA): Net Income / Average Total Assets',
  return_total_capital: 'Return on Total Capital: EBIT / Total Debt and Equity',
  return_equity: "Return on Equity (ROE): Net Income / Average Shareholder's Equity"
}

export const ratioLiquidEnteries = {
  current_ratio: 'Current Ratio (Current Assets / Current Liabilities)',
  quick_ratio: 'Quick Ratio (Cash + Accounts Receivables / Current Liabilities)',
  cash_ratio: 'Cash Ratio (Cash / Current Liabilities)'
}

export const ratioSolvencyEnteries = {
  solvency_ratio: 'Solvency Ratio (Net Income + D&A / Total Liabilities)',
  debt_equity: 'Debt-to-Equity (Total Debt / Total Equity)',
  debt_assets: 'Debt-to-Assets (Total Debt / Total Assets)',
  equity_assets: 'Equity-to-Assets (Total Equity / Total Assets)',
  net_debt_ebitda: 'Net Debt-to-EBITDA (Net Debt / EBITDA)',
  total_debt_ebitda: 'Total Debt-to-EBITDA (Total Debt / EBITDA)	',
  interest_coverage_ratio: 'Interest Coverage Ratio (EBIT / Interest Expense)',
  debt_coverage_ratio: 'Debt Service Coverage Ratio (NOI / Total Debt Service)'
}

export const kpiIncomeStatementActualRatio = [
  { title: 'Solvency Ratio (Net Income + D&A / Total Liabilities)', key: 'solvencyratio' },
  { title: 'Debt-to-Equity (Total Debt / Total Equity)', key: 'debtequity' },
  { title: 'Debt-to-Assets (Total Debt / Total Assets)', key: 'debtassets' },
  { title: 'Equity-to-Assets (Total Equity / Total Assets)', key: 'equityassets' },
  { title: 'Net Debt-to-EBITDA (Net Debt / EBITDA)', key: 'netdebtebitda' },
  { title: 'Total Debt-to-EBITDA (Total Debt / EBITDA)', key: 'totaldebtebitda' },
  { title: 'Interest Coverage Ratio (EBIT / Interest Expense)', key: 'interestcoverageratio' },
  { title: 'Debt Service Coverage Ratio (NOI / Total Debt Service)', key: 'debtcoverageratio' }
]

export const kpiIncomeStatementProjectionRatio = [
  { title: 'Cash Ratio', key: 'cashratio' },
  { title: 'Cash Return Assets', key: 'cashreturnassets' },
  { title: 'Current Ratio', key: 'currentratio' },
  { title: 'Debt Assets', key: 'debtassets' },
  { title: 'Debt Cover Age Ratio', key: 'debtcoverageratio' },
  { title: 'Debt Equity', key: 'debtequity' },
  { title: 'EBITDA Margin', key: 'ebitdamargin' },
  { title: 'Equity Assets', key: 'equityassets' },
  { title: 'Gross Margin', key: 'grossmargin' },
  { title: 'Interest Cover Age Ratio', key: 'interestcoverageratio' },
  { title: 'Latest', key: 'latest' },
  { title: 'Net Debt EBITDA', key: 'netdebtebitda' },
  { title: 'Net Income Margin', key: 'netincomemargin' },
  { title: 'Operating Profit Margin', key: 'operatingprofitmargin' },
  { title: 'Operating Return Assets', key: 'operatingreturnassets' },
  { title: 'Pre-Tax Margin: EBT / Revenue', key: 'pretaxmargin' },
  { title: 'Quick Ratio', key: 'quickratio' },
  { title: 'Return Assets', key: 'returnassets' },
  { title: 'Return Equity', key: 'returnequity' },
  { title: 'Return Total Capital', key: 'returntotalcapital' },
  { title: 'Solvency Ratio', key: 'solvencyratio' },
  { title: 'Total Debt EBITDA', key: 'totaldebtebitda' }
]

export const balanceSheetEnteries = {
  days_sales_outstanding: 'Avg. Days Sales Outstanding (DSO)',
  inventory_days: 'Avg. Inventory Days',
  other_current_assets_percent: 'Avg. Other Current Assets as % of Revenue',
  days_payable_outstanding: 'Avg. Days Payable Outstanding (DPO)',
  accrued_liabilities_percent: 'Avg. Accrued Liabilities as % of COGS',
  other_current_liabilities_percent: 'Avg. Other Current Liabilties as % of COGS'
}

export const cashflowEntries = {
  asset_sales_percent_revenue: 'Avg. Asset Sales as % of Revenue',
  cap_ex_percent_revenue: 'Avg. Capex as % of Revenue',
  dividends_paid_percent_income: 'Avg. Dividends Paid as % of Net Income',
  ffo_percent_revenue: 'Avg. FFO as % of Revenue',
  investing_percent_revenue: 'Avg. Other Investing Activities as % of Revenue'
}

export const cashflowEntriesProjected = {
  asset_sales_percent_revenue: 'Avg. Asset Sales as % of Revenue',
  cap_ex_percent_revenue: 'Avg. Capex as % of Revenue',
  dividends_paid_percent_income: 'Avg. Dividends Paid as % of Net Income',
  ffo_percent_revenue: 'Avg. FFO as % of Revenue',
  cfo_percent_revenue: 'Avg. CFO as % of Revenue',
  cfo_percent_ebitda: 'Avg. CFO as % of EBITDA'
}

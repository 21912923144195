import React from 'react'

export { OverlayLoader } from 'components/OverlayLoader'

export const Button = React.lazy(() => import('components/Button'))
export const Container = React.lazy(() => import('components/Container'))
export const CustomSelect = React.lazy(() => import('components/Select'))
export const DropDown = React.lazy(() => import('components/Dropdown'))
export const Graph = React.lazy(() => import('components/Graph'))
export const Header = React.lazy(() => import('components/Header'))
export const Heading = React.lazy(() => import('components/Heading'))
export const Image = React.lazy(() => import('components/Image'))
export const Input = React.lazy(() => import('components/Input'))
export const SideBar = React.lazy(() => import('components/Sidebar'))
export const SubHeading = React.lazy(() => import('components/SubHeading'))
export const Table = React.lazy(() => import('components/Table'))
export const Text = React.lazy(() => import('components/Text'))
export const Modal = React.lazy(() => import('components/Modal'))

import { Suspense, useEffect, useLayoutEffect } from 'react'
import { Switch } from 'react-router-dom'
import { useRecoilState, useRecoilValue } from 'recoil'
import { currentUserNameState, selectedCompanyState, selectedCompanyIdState } from 'store/companies'
import { activeSidebarLink, isLoggedInState, loadingState } from 'store'
import {
  allScenariosState,
  allScenariosDropdownState,
  selectedScenarioState,
  uniqueScenarioYearsState
} from 'store/scenarios'
import Auth from 'auth'
import { Header, OverlayLoader, SideBar } from 'components'
import PrivateRoute from 'routes/PrivateRoute'
import { fetchCompany } from 'api/companies'
import { fetchScenarios } from 'api/scenario'
import PublicRoute from 'routes/PublicRoute'
import { withoutHeaderList, withoutSidebarList } from 'helpers'
import {
  Dashboard,
  Login,
  CompanyDetail,
  ChangePassword,
  EmptyState,
  ForgotPassword,
  Documents,
  ManageUser,
  KPIs,
  Valuation,
  BenchMarking,
  Rating,
  Metrics,
  Ratios,
  Report
} from 'containers'

require('dotenv').config()

const App = () => {
  let companyId = window.location.pathname.split('/')[1]

  const [isLoggedIn, setIsLoggedIn] = useRecoilState(isLoggedInState)
  const [activeLink] = useRecoilState(activeSidebarLink)
  let company = useRecoilValue(currentUserNameState({ id: companyId }))
  let [selectedCompany, setSelectedCompany] = useRecoilState(selectedCompanyState)
  const [, setAllScenarios] = useRecoilState(allScenariosState)
  const [, setLoading] = useRecoilState(loadingState)
  const [, setScenarioDropdown] = useRecoilState(allScenariosDropdownState)
  const [, setScenario] = useRecoilState(selectedScenarioState)
  const [selectedCompanyId, setSelectedCompanyId] = useRecoilState(selectedCompanyIdState)
  const [, setUniqueYears] = useRecoilState(uniqueScenarioYearsState)

  useLayoutEffect(() => {
    setIsLoggedIn(Auth.isAuthenticated())
  }, [activeLink])

  const renderHeader = !withoutHeaderList.includes(window.location.pathname) && <Header />
  const renderSidebar = isLoggedIn && !withoutSidebarList.includes(window.location.pathname) && (
    <SideBar />
  )

  useEffect(async () => {
    if (activeLink === 'Companies' || activeLink == 'Documents') return

    if (Number.isInteger(parseInt(companyId))) {
      setLoading(true)

      if (company == undefined && !selectedCompany.id) {
        setSelectedCompany(await fetchCompany(companyId))
        setSelectedCompanyId(companyId)
      } else if (company) {
        setSelectedCompany(company)
        setSelectedCompanyId(companyId)
      }

      let scenarios = await fetchScenarios(companyId)

      let sc = {
        name: scenarios[0].name,
        id: scenarios[0].id,
        year: scenarios[0].year,
        isBase: scenarios[0].isBase
      }

      if (selectedCompanyId != companyId) setScenario(sc)

      setUniqueYears([...new Set(scenarios.map(data => data.year))].sort())
      setAllScenarios(scenarios)

      setScenarioDropdown(
        scenarios.map(scenario => ({
          id: scenario.id,
          name: scenario.name,
          year: scenario.year
        }))
      )

      setLoading(false)
    }
  }, [activeLink])

  return (
    <div className={'app'}>
      <Suspense fallback={<OverlayLoader />}>
        {renderHeader}

        <div className={'flex-container'} style={{ justifyContent: 'center' }}>
          {renderSidebar}
          <Switch>
            <PublicRoute unprotected={true} component={Login} path={['/', '/login']} exact />
            <PrivateRoute component={Dashboard} path={'/dashboard'} exact />
            <PrivateRoute component={Documents} path={'/documents'} exact />
            <PrivateRoute component={EmptyState} path={'/empty-state'} exact />
            <PrivateRoute component={CompanyDetail} path={'/:id/balance-sheet'} exact />
            <PrivateRoute component={CompanyDetail} path={'/:id/income-statement'} exact />
            <PrivateRoute component={CompanyDetail} path={'/:id/cash-flow'} exact />
            <PrivateRoute component={KPIs} path={'/:id/kpis-income-statement'} exact />
            <PrivateRoute component={Metrics} path={'/:id/metrics-income-statement'} exact />
            <PrivateRoute component={Metrics} path={'/:id/metrics-balance-sheet'} exact />
            <PrivateRoute component={Metrics} path={'/:id/metrics-cash-flow'} exact />
            <PrivateRoute component={Ratios} path={'/:id/ratios'} exact />
            <PrivateRoute component={Valuation} path={'/:id/valuation'} exact />
            <PrivateRoute component={Rating} path={'/:id/rating'} exact />
            <PrivateRoute component={BenchMarking} path={'/:id/benchmarking'} exact />
            <PrivateRoute component={ManageUser} path={'/manage-user'} exact />
            <PrivateRoute component={Report} path={'/:id/reports'} exact />
            <PrivateRoute component={ChangePassword} path={'/change-password'} exact />
            <PublicRoute unprotected={true} component={ForgotPassword} path={'/forgot-password'} />
          </Switch>
        </div>
      </Suspense>
    </div>
  )
}

export default App

export const tabList = ['INCOME STATEMENT', 'BALANCE SHEET', 'CASH FLOW STATEMENT']

export const nestedTabList = ['Drivers', 'Projections', 'Financials']

export const getFilteredCategories = (data, dataKey) => getCategories(data, dataKey).map(elm => elm)

export const getCategories = (data, dataKey) => Object.keys(data[dataKey])

export const getFilteredGraphData = (data, dataKey) =>
  getGraphData(data, dataKey).map(elm => {
    if (elm.key.includes('A')) {
      return {
        y: elm.value,
        marker: { fillColor: '#68B6E9' },
        dragDrop: { draggableY: false },
        color: '#68B6E9'
      }
    } else {
      return {
        y: elm.value,
        marker: { fillColor: '#64838E' }
      }
    }
  })

export const getGraphData = (data, dataKey) => {
  if (Object.keys(data).length === 0) return []

  return getCategories(data, dataKey).map(key => ({ key: key, value: data[dataKey][key] }))
}

export const noscrollbody = () => {
  document.body.style.overflow = 'hidden'
}

export const scrollbody = () => {
  document.body.style.overflow = 'scroll'
}

export const renderListOption = list =>
  list.map(item => (
    <option value={`${item.value}`} key={`${item.value}`}>
      {item.label}
    </option>
  ))

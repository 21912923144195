import { BrowserRouter as Router } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import { RecoilRoot } from 'recoil'

import './index.scss'
import '@szhsin/react-menu/dist/index.css'
import 'react-confirm-alert/src/react-confirm-alert.css'
import App from './App'
import reportWebVitals from './reportWebVitals'

ReactDOM.render(
  <StrictMode>
    <RecoilRoot>
      <Router>
        <ToastContainer />

        <App />
      </Router>
    </RecoilRoot>
  </StrictMode>,
  document.getElementById('root')
)

reportWebVitals()

import { Route, Redirect } from 'react-router-dom'
import auth0Client from 'auth'

export default ({ component: Component, unprotected, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      auth0Client.isAuthenticated() && unprotected ? (
        <Redirect to={'/dashboard'} />
      ) : (
        <Component {...props} />
      )
    }
  />
)

import { instance } from 'api'

export const fetchCompanies = (page, perPage) =>
  new Promise((resolve, reject) => {
    let params = page && perPage ? `?page=${page}&per_page=${perPage}` : ''

    instance
      .get(`/companies/${params}`)
      .then(response => resolve(response.data.results))
      .catch(err => reject(err))
  })

export const fetchCompany = id =>
  new Promise((resolve, reject) => {
    instance
      .get(`/companies/${id}`)
      .then(response => resolve(response.data.results))
      .catch(error => reject(error))
  })

export const fetchCompaniesCount = () =>
  new Promise((resolve, reject) => {
    instance
      .get(`/companies/count`)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const deleteCompany = id =>
  new Promise((resolve, reject) => {
    instance
      .delete(`/companies/${id}`)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const createCompany = values => {
  let postForm = new FormData()

  postForm.append('file', values.file)
  postForm.append('company_type', values.type)
  postForm.append('name', values.companyName)
  postForm.append('reporting_period', values.duration)
  postForm.append('company_industry_category', values.industry)
  postForm.append('description', values.description)
  postForm.append('year', values.year)

  return new Promise(resolve => {
    instance
      .post('/companies/', postForm)
      .then(response => resolve(response))
      .catch(error => resolve(error))
  })
}

export const uploadStatement = (id, values) => {
  let postForm = new FormData()

  postForm.append('file', values.file)
  postForm.append('period', values.duration)
  postForm.append('industry', values.industry)
  postForm.append('company_type', 'public')
  postForm.append('description', values.description)
  postForm.append('year', values.year)

  return new Promise((resolve, reject) => {
    instance
      .post(`/companies/${id}/statement_uploads/`, postForm)
      .then(response => resolve(response))
      .catch(error => reject(error))
  })
}

export const fetchActualIncomeStatement = id =>
  new Promise((resolve, reject) => {
    instance
      .get(`/companies/${id}/income_statements/actual`)
      .then(response => resolve(response.data.results))
      .catch(error => reject(error))
  })

export const fetchProjectionIncomeStatement = id =>
  new Promise((resolve, reject) => {
    instance
      .get(`/companies/${id}/income_statements/projected`)
      .then(response => resolve(response.data.results))
      .catch(error => reject(error))
  })

export const fetchGraphProjectionsIncomeStatment = (companyId, scenarioId) =>
  new Promise((resolve, reject) => {
    instance
      .get(`/companies/${companyId}/income_statements/graph_projections/${scenarioId}`)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const fetchDriversIncomeStatment = (companyId, scenarioId) =>
  new Promise((resolve, reject) => {
    instance
      .get(`/companies/${companyId}/income_statements/graph_drivers/${scenarioId}`)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const fetchFinancialsIncomeStatment = (companyId, scenarioId) =>
  new Promise((resolve, reject) => {
    instance
      .get(`/companies/${companyId}/income_statements/financials/${scenarioId}`)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const fetchActualBalanceSheet = id =>
  new Promise((resolve, reject) => {
    instance
      .get(`/companies/${id}/balance_sheet/actual`)
      .then(response => resolve(response.data.results))
      .catch(error => reject(error))
  })

export const fetchProjectionBalanceSheet = id =>
  new Promise((resolve, reject) => {
    instance
      .get(`/companies/${id}/balance_sheet/projected`)
      .then(response => resolve(response.data.results))
      .catch(error => reject(error))
  })

export const fetchGraphProjectionsBalanceSheet = (companyId, scenarioId) =>
  new Promise((resolve, reject) => {
    instance
      .get(`/companies/${companyId}/balance_sheet/graph_projections/${scenarioId}`)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const fetchDriversBalanceSheet = (companyId, scenarioId) =>
  new Promise((resolve, reject) => {
    instance
      .get(`/companies/${companyId}/balance_sheet/graph_drivers/${scenarioId}`)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const fetchFinancialsBalanceSheet = (companyId, scenarioId) =>
  new Promise((resolve, reject) => {
    instance
      .get(`/companies/${companyId}/balance_sheet/financials/${scenarioId}`)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const fetchActualCashFlow = id =>
  new Promise((resolve, reject) => {
    instance
      .get(`/companies/${id}/cash_flows/actual`)
      .then(response => resolve(response.data.results))
      .catch(error => reject(error))
  })

export const fetchProjectionCashFlow = id =>
  new Promise((resolve, reject) => {
    instance
      .get(`/companies/${id}/cash_flows/projected`)
      .then(response => resolve(response.data.results))
      .catch(error => reject(error))
  })

export const fetchGraphProjectionsCashFlow = (companyId, scenarioId) =>
  new Promise((resolve, reject) => {
    instance
      .get(`/companies/${companyId}/cash_flows/graph_projections/${scenarioId}`)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const fetchDriversCashFlow = (companyId, scenarioId) =>
  new Promise((resolve, reject) => {
    instance
      .get(`/companies/${companyId}/cash_flows/graph_drivers/${scenarioId}`)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const fetchFinancialsCashFlow = (companyId, scenarioId) =>
  new Promise((resolve, reject) => {
    instance
      .get(`/companies/${companyId}/cash_flows/financials/${scenarioId}`)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const updateIncomeStatementGraphDriver = (companyId, scenarioId, data) =>
  new Promise((resolve, reject) => {
    instance
      .put(`companies/${companyId}/income_statements/graph_drivers/${scenarioId}`, data)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const updateBalanceSheetGraphDriver = (companyId, scenarioId, data) =>
  new Promise((resolve, reject) => {
    instance
      .put(`companies/${companyId}/balance_sheet/graph_drivers/${scenarioId}`, data)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const updateCashFlowGraphDriver = (companyId, scenarioId, data) =>
  new Promise((resolve, reject) => {
    instance
      .put(`companies/${companyId}/cash_flows/graph_drivers/${scenarioId}`, data)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const updatedDriverGraphIncomeStatement = (companyId, scenarioId, values) =>
  new Promise((resolve, reject) => {
    instance
      .get(
        `/companies/${companyId}/income_statements/update_scenario/?id=${scenarioId}&values=${JSON.stringify(
          values
        )}`
      )
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const updatedDriverGraphBalanceSheet = (companyId, scenarioId, values) =>
  new Promise((resolve, reject) => {
    instance
      .get(
        `/companies/${companyId}/balance_sheet/update_scenario/?id=${scenarioId}&values=${JSON.stringify(
          values
        )}`
      )
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const updatedDriverGraphCashFlow = (companyId, scenarioId, values) =>
  new Promise((resolve, reject) => {
    instance
      .get(
        `/companies/${companyId}/cash_flows/update_scenario/?id=${scenarioId}&values=${JSON.stringify(
          values
        )}`
      )
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const checkCompanyName = name =>
  new Promise((resolve, reject) => {
    instance
      .get(`/companies/check-company-name?name=${name}`)
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const getCompanyScenarios = () =>
  new Promise((resolve, reject) => {
    instance
      .get('/companies/get-company-scenarios/')
      .then(response => resolve(response.data.results))
      .catch(error => reject(error))
  })

import { instance } from 'api'

export const fetchScenarios = id =>
  new Promise((resolve, reject) => {
    instance
      .get(`/companies/${id}/scenarios/`)
      .then(response =>
        resolve(
          response.data.results.map(scenario => ({
            id: scenario.id,
            name: scenario.name,
            year: scenario.year,
            isBase: scenario.is_base
          }))
        )
      )
      .catch(error => reject(error))
  })

export const createScenario = (id, values, previousScenarioId) =>
  new Promise((resolve, reject) => {
    instance
      .post(`/companies/${id}/scenarios/`, {
        name: values.name,
        year: values.year,
        previous_scenario_id: previousScenarioId
      })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const updateScenario = (companyId, id, values) =>
  new Promise((resolve, reject) => {
    instance
      .put(`/companies/${companyId}/scenarios/${id}`, { name: values.name })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const deleteScenario = (companyId, id) =>
  new Promise(resolve => {
    instance
      .delete(`/companies/${companyId}/scenarios/${id}`)
      .then(response => resolve(response))
      .catch(error => resolve(error))
  })

export const saveScenario = (companyId, scenarioId, type, data) =>
  new Promise(resolve => {
    let url = null

    if (type == 'income-statement') {
      url = `/companies/${companyId}/income_statements/${scenarioId}/save_scenario/`
    } else if (type == 'balance-sheet') {
      url = `/companies/${companyId}/balance_sheet/${scenarioId}/save_scenario/`
    } else if (type == 'cash-flow') {
      url = `/companies/${companyId}/cash_flows/${scenarioId}/save_scenario/`
    }

    return instance
      .post(url, data)
      .then(response => resolve(response.data))
      .catch(error => resolve(error))
  })

import { instance } from 'api'

export const signIn = (email, password) =>
  new Promise((resolve, reject) => {
    instance
      .post(`/users/login`, {
        username: email,
        password: password
      })
      .then(response => {
        if (response.data.error) {
          reject(response.data.error_description)
        } else {
          instance.defaults.headers.common.authorization = `Bearer ${response.data.results.token_info.access_token}`

          resolve(response.data.results)
        }
      })
      .catch(error => reject(error))
  })

export const logout = () =>
  new Promise((resolve, reject) => {
    instance
      .get('/users/logout')
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const passwordForgot = values =>
  new Promise((resolve, reject) => {
    instance
      .post('/users/forgotpassword', {
        email: values.email
      })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const checkOldPassword = (email, password) =>
  new Promise((resolve, reject) => {
    instance
      .post(`/users/check_old_password`, {
        username: email,
        password: password
      })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

export const saveNewPassword = (user_id, password) =>
  new Promise((resolve, reject) => {
    instance
      .put(`/users/${user_id}/save_new_password`, {
        password: password
      })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
  })

import React from 'react'

export const Login = React.lazy(() => import('containers/Login'))
export const EmptyState = React.lazy(() => import('containers/EmptyState'))
export const Dashboard = React.lazy(() => import('containers/Dashboard'))
export const BalanceSheet = React.lazy(() => import('containers/CompanyDetail/BalanceSheet'))
export const CompanyDetail = React.lazy(() => import('containers/CompanyDetail'))
export const ChangePassword = React.lazy(() => import('containers/ChangePassword'))
export const IncomeStatement = React.lazy(() => import('containers/CompanyDetail/IncomeStatement'))
export const CashFlow = React.lazy(() => import('containers/CompanyDetail/CashFlow'))
export const ForgotPassword = React.lazy(() => import('containers/ForgotPassword'))
export const Documents = React.lazy(() => import('containers/Documents'))
export const ManageUser = React.lazy(() => import('containers/ManageUser'))
export const KPIs = React.lazy(() => import('containers/KPIs'))
export const Valuation = React.lazy(() => import('containers/Valuation'))
export const BenchMarking = React.lazy(() => import('containers/Benchmarking'))
export const Rating = React.lazy(() => import('containers/Rating'))
export const Metrics = React.lazy(() => import('containers/Metrics'))
export const Ratios = React.lazy(() => import('containers/Ratios'))
export const Report = React.lazy(() => import('containers/Report'))

export const cashFlowDriversList = [
  {
    type: 'areaspline',
    title: 'Dividens Paid',
    key: 'dividends_paid',
    axisTitle: 'USD (billions)',
    tooltip: 'B',
    modalTitle: 'Dividens Paid ($ in millions)'
  },
  {
    type: 'areaspline',
    title: 'Capex (% of Revenue)',
    key: 'cap_ex_percent',
    axisTitle: 'As % of Revenue',
    tooltip: '%',
    modalTitle: 'Capex (% of Revenue)'
  },
  {
    type: 'areaspline',
    title: 'Asset Sales (% of Revenue)',
    key: 'asset_sales_percent',
    axisTitle: 'As % of Revenue',
    tooltip: '%',
    modalTitle: 'Asset Sales (% of Revenue)'
  },
  {
    type: 'areaspline',
    title: 'Other Investing Activities (% of Revenue)',
    key: 'other_investment_percent',
    axisTitle: 'As % of Revenue',
    tooltip: '%',
    modalTitle: 'Other Investing Activities (% of Revenue)'
  }
]

export const cashFlowProjectionsList = [
  {
    type: 'column',
    title: 'Cash Flow from Operating Activities (CFO)',
    key: 'cash_flow_operating',
    axisTitle: 'USD (in billions)',
    tooltip: 'B'
  },
  {
    type: 'column',
    title: 'Cash Flow from Investing Activities (CFI)',
    key: 'cash_flow_investing',
    axisTitle: 'USD (in billions)',
    tooltip: 'B'
  },
  {
    type: 'column',
    title: 'Cash Flow from Financing Activities (CFF)',
    key: 'cash_flow_financing',
    axisTitle: 'USD',
    tooltip: 'USD'
  },
  {
    type: 'column',
    title: 'Net Change in Cash',
    key: 'net_change_in_cash',
    axisTitle: 'USD (in billions)',
    tooltip: 'B'
  }
]

export const cashFlowEntries = {
  net_income: 'Net Income',
  daa: '(+) Depreciation & Amortization (D&A)',
  funds_from_operations: 'Funds from Operations',
  acc_receivable_delta: '(+/–) Δ in Accounts Receivable',
  inventories_delta: '(+/–) Δ in Inventories',
  other_current_assets: '(+/–) Δ in Other Current Assets',
  acc_payable: '(+/–) Δ in Accounts Payable',
  accrued_liabilities: '(+/–) Δ in Accrued Liabilities',
  other_current_liabilities: '(+/–) Δ in Other Current Liabilities',
  cash_flow_operating: 'Cash Flow from Operating Activities ("CFO")',
  total_expenditure: '(–) Total Capital Expenditures',
  asset_sales: '(+/-) Asset Sales',
  other_investing_activities: '(+/–) Other Investing Activities',
  cash_flow_investing: 'Cash Flow from Investing Activities ("CFI")',
  debt_issued: '(+/–) Debt Issued (Retired)',
  common_stock_issued: '(+/–) Common Stock Issued (Retired)',
  dividends_paid: '(–) Dividends Paid',
  cash_flow_financing: 'Cash Flow from Financing Activities ("CFF")',
  net_change_in_cash: 'Net Change in Cash'
}

export const highlights = [
  'Funds from Operations',
  'Cash Flow from Operating Activities ("CFO")',
  'Cash Flow from Investing Activities ("CFI")',
  'Cash Flow from Financing Activities ("CFF")',
  'Net Change in Cash'
]

import { atom } from 'recoil'
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

export const allScenariosState = atom({
  key: 'allScenariosState',
  default: []
})

export const allScenariosDropdownState = atom({
  key: 'allScenariosDropdownState',
  default: []
})

export const selectedScenarioState = atom({
  key: 'companySpecificselectedScenario',
  default: [],
  effects_UNSTABLE: [persistAtom]
})

export const uniqueScenarioYearsState = atom({
  key: 'companySpecificUniqueScenarioYears',
  default: []
})

import { Route, Redirect } from 'react-router-dom'
import auth0Client from 'auth'

export default ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      auth0Client.isAuthenticated() ? <Component {...props} /> : <Redirect to={'/login'} />
    }
  />
)

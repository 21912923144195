export const dashboardSidebar = [
  { text: 'Companies', link: '/dashboard', key: 'dashboard' },
  { text: 'Documents', link: '/documents', key: 'documents' }
]

export const companySpecificSidebar = [
  {
    text: 'Financial Model',
    link: 'income-statement',
    key: ['/income-statement', '/balance-sheet', '/cash-flow']
  },
  {
    text: 'KPIs',
    link: 'kpis-income-statement',
    classes: 'nested-item',
    key: ['/metrics-income-statement', '/metrics-balance-sheet', '/metrics-cash-flow', '/ratio'],
    nested: [
      {
        text: 'Metrics',
        link: 'metrics-income-statement',
        key: ['/metrics-income-statement', '/metrics-balance-sheet', '/metrics-cash-flow']
      },
      { text: 'Ratio', link: 'ratios', key: 'ratio' }
    ]
  },
  { text: 'Valuation', link: 'valuation', key: 'valuation' },
  { text: 'Ratings', link: 'rating', key: 'rating' },
  { text: 'Benchmark', link: 'benchmarking', key: 'benchmarking' },
  { text: 'Report', link: 'reports', key: 'report' }
]

export const withoutHeaderList = []

export const withoutSidebarList = ['/empty-state']

export const balanceSheetDriversList = [
  {
    type: 'areaspline',
    title: 'Days Sales Outstanding',
    key: 'days_sales_outstanding',
    axisTitle: 'Days',
    tooltip: 'Days',
    modalTitle: 'Days Sales Outstanding'
  },
  {
    type: 'areaspline',
    title: 'Inventory Days',
    key: 'inventory_days',
    axisTitle: 'Days',
    tooltip: 'Days',
    modalTitle: 'Inventory Days'
  },
  {
    type: 'areaspline',
    title: 'Other Current Assets (% of Revenue)',
    key: 'other_current_assets_percent',
    axisTitle: 'As % of Revenue',
    tooltip: '%',
    modalTitle: 'Other Current Assets (% of Revenue)'
  },
  {
    type: 'areaspline',
    title: 'Days Payable Outstanding',
    key: 'days_payable_outstanding',
    axisTitle: 'Days',
    tooltip: 'Days',
    modalTitle: 'Days Payable Outstanding'
  },
  {
    type: 'areaspline',
    title: 'Accrued Liabilities (% of COGS)',
    key: 'accrued_liabilities_percent',
    axisTitle: 'As % of COGS',
    tooltip: '%',
    modalTitle: 'Accrued Liabilities (% of COGS)'
  },
  {
    type: 'areaspline',
    title: 'Other Current Liabilities (% of COGS)',
    key: 'other_current_liabilities_percent',
    axisTitle: 'As % of COGS',
    tooltip: '%',
    modalTitle: 'Other Current Liabilities (% of COGS)'
  }
]

export const balanceSheetProjectionsList = [
  {
    type: 'column',
    title: 'Total Current Assets (Excluding Cash)',
    key: 'total_current_asset_ex_cash',
    axisTitle: 'USD (in billions)',
    tooltip: 'B'
  },
  {
    type: 'column',
    title: 'Total Assets (Excluding Cash)',
    key: 'total_assets_ex_cash',
    axisTitle: 'USD (in billions)',
    tooltip: 'B'
  },
  {
    type: 'column',
    title: 'Total Assets',
    key: 'total_assets',
    axisTitle: 'USD (in billions)',
    tooltip: 'B'
  },
  {
    type: 'column',
    title: 'Total Current Liabilities',
    key: 'total_current_liabilities',
    axisTitle: 'USD (in billions)',
    tooltip: 'B'
  },
  {
    type: 'column',
    title: 'Total Liabilities',
    key: 'total_liabilities',
    axisTitle: 'USD (in billions)',
    tooltip: 'B'
  },
  {
    type: 'column',
    title: 'Total Liabilities & Shareholders Equity',
    key: 'total_liabilities_and_equity',
    axisTitle: 'USD (in billions)',
    tooltip: 'B'
  }
]
